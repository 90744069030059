import { HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { CloudFunctionHttpInterceptorProvider } from './cloud-function-http-interceptor';

export function provideHttp() {
  return [
    importProvidersFrom(HttpClientModule),
    CloudFunctionHttpInterceptorProvider,
  ];
}
