import { importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@env';
import { provideCustomAnalytics } from './analytics';
import { provideCustomAuth } from './auth';
import { provideCustomFirestore } from './firestore.ts';

export function provideFirebase() {
  return [
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideCustomFirestore(),
      provideCustomAuth(),
      provideCustomAnalytics()
    ),
  ];
}
