import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  info(message: string, duration = 2000) {
    this.snackBar.open(message, 'Fermer', {
      duration,
      panelClass: ['mat-snack-bar-info'],
    });
  }

  success(message: string, duration = 2000) {
    this.snackBar.open(message, 'Fermer', {
      duration,
      panelClass: ['mat-snack-bar-success'],
    });
  }

  warning(message: string, duration: number | null = 5000) {
    this.snackBar.open(message, 'Fermer', {
      duration: duration ?? undefined,
      panelClass: ['mat-snack-bar-warning'],
    });
  }

  error(message: string, duration: number | null = null) {
    this.snackBar.open(message, 'Fermer', {
      duration: duration ?? undefined,
      panelClass: ['mat-snack-bar-error'],
    });
  }
}
