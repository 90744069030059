import { ErrorHandler, Injectable, inject } from '@angular/core';
import { createErrorHandler } from '@sentry/angular';
import { BehaviorSubject } from 'rxjs';
import { CustomError } from '@core/errors';
import { SnackbarService } from '../snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private sentryHandler = createErrorHandler();
  private snackbar = inject(SnackbarService);

  private _lastError = new BehaviorSubject<string | undefined>(undefined);
  readonly lastError = this._lastError.asObservable();

  handleError(error: any) {
    if (error.promise && error.rejection) {
      error = error.rejection;
    }
    if (this.shouldReport(error)) {
      this.doReport(error);
    }

    if (this.shouldDisplay(error)) {
      const message = this.doDisplay(error);
      this.setLastError(message);
    } else {
      this.setLastError(undefined);
    }
  }
  private setLastError(message: string | undefined) {
    this._lastError.next(message);
  }

  private shouldReport(_error: any) {
    return true;
  }

  private shouldDisplay(error: any) {
    const message: string = error.message;

    // Some exceptions here
    if (message.includes('analytics/cookies-not-enabled')) {
      return false;
    }

    return true;
  }

  private doReport(error: any) {
    this.sentryHandler.handleError(error);
  }

  private doDisplay(error: any) {
    console.error('handleError', error);
    let message: string = error.message;

    if (!(error instanceof CustomError)) {
      message = 'Unexpected error: ' + message;
    }

    error.isWarning
      ? this.snackbar.warning(message, null) // Keep open
      : this.snackbar.error(message);

    return message;
  }
}
