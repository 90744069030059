import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';

export function provideCustomAnalytics() {
  return provideAnalytics(
    () => getAnalytics()
    // [
    //   { provide: APP_NAME, useValue: 'Coach Console' },
    //   { provide: APP_VERSION, useValue: environment.version },
    //   { provide: DEBUG_MODE, useValue: false },
    //   Automatic tracking in Analytics:
    //   ScreenTrackingService, // screen_view
    //   UserTrackingService, // user_id
    // ]
  );
}
