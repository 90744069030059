import debug from 'debug';
import { Observable, OperatorFunction } from 'rxjs';

const performance = window.performance;

// eslint-disable-next-line no-console
debug.log = console.log.bind(console);

const logger = debug('remedee');

interface Debugger extends debug.Debugger {
  /**
   * Utility operator that will log any "call" to an observable, ie. usually for a
   * observable that terminates after emitting 1 value.
   */
  logCalls<T>(formatter: any, ...args: any[]): OperatorFunction<T, T>;

  /**
   * Utility operator that will log usage of an observable and generate measures.
   *
   * You should add it at the end of pipes of the observable, before any `share()`
   * operator so that it makes sense.
   */
  logSubscriptions<T>(formatter: any, ...args: any[]): OperatorFunction<T, T>;

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Performance/mark) */
  mark: typeof Performance.prototype.mark;

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Performance/measure) */
  measure: typeof Performance.prototype.measure;
}

/**
 * Create a logger with a name, example 'services:auth'.
 *
 * In your browser, use localStorage.setItem('debug', 'remedee:*') and you will see the logs
 * in your console :)
 */
export default function createLogger(name: string) {
  const log = logger.extend(name) as Debugger;

  log.logCalls = (formatter: any, ...args: any[]) =>
    _logObservable(log, { measureFirstValue: true }, formatter, args)();

  log.logSubscriptions = (formatter: any, ...args: any[]) =>
    _logObservable(
      log,
      { measureSubscription: true, measureFirstValue: true },
      formatter,
      args
    )();

  log.mark = (...args) => {
    if (log.enabled && performance) {
      return performance.mark(...args);
    }
    return undefined as any;
  };

  log.measure = (...args) => {
    if (log.enabled && performance) {
      performance.measure(...args);
    }
    return undefined as any;
  };

  return log;
}

interface LogObservableOptions {
  measureSubscription?: boolean;
  measureFirstValue?: boolean;
}

let i = 0;

function _logObservable(
  log: Debugger,
  options: LogObservableOptions,
  formatter: any,
  args: any[]
): <T = any>() => OperatorFunction<T, T> {
  return () => (source) =>
    new Observable((observer) => {
      const id = i++;
      const startMark = `${formatter}-${id}-Subscribe`;
      let firstValue = false;

      if (options.measureSubscription) {
        log(startMark, ...args);
      }
      if (options.measureFirstValue) {
        firstValue = true;
      }

      log.mark(startMark);

      const sub = source.subscribe({
        next(value) {
          if (firstValue && options.measureFirstValue) {
            const endMark = `${formatter}-${id}-FirstValue`;
            log(endMark, ...args);
            log.mark(endMark);
            log.measure(`${formatter}-FirstValue`, startMark, endMark);
            firstValue = false;
          }
          observer.next(value);
        },
        error(err) {
          log(
            formatter,
            'error',
            err?.message || err,
            err?.stack,
            ...(args ?? [])
          );
          if (options.measureSubscription) {
            const endMark = `${formatter}-${id}-Error`;
            log(endMark, ...args);
            log.mark(endMark);
            log.measure(formatter, startMark, endMark);
          }
          observer.error(err);
        },
        complete() {
          if (options.measureSubscription) {
            const endMark = `${formatter}-${id}-Complete`;
            log(endMark, ...args);
            log.mark(endMark);
            log.measure(formatter, startMark, endMark);
          }
          observer.complete();
        },
      });

      return () => {
        if (options.measureSubscription) {
          const endMark = `${formatter}-${id}-Unsubscribe`;
          log(endMark, ...args);
          log.mark(endMark);
          log.measure(`${formatter}-Subscription`, startMark, endMark);
        }
        sub.unsubscribe();
      };
    });
}
