export enum AnalyticsEvents {
  /** Coaching events */
  SEND_MESSAGE = 'SEND_MESSAGE',

  /** Report events */
  REPORT_OPEN = 'REPORT_OPEN',
}

/**
 * Analytics event parameters used when a coach sends 1 or several chat messages,
 * to 1 or several users.
 */
export interface SendMessageEvent {
  /**
   * Total number of messages sent.
   * Example: if the coach selects 2 clients and perform a step with 3 messages, total will be 2x3 = 6 messagesCount.
   */
  messagesCount: number;
  /** Number of different users receiving the messages. */
  usersCount: number;
  /** Total number of reports shared by this chat action. */
  reportsCount?: number;
}

export interface ReportEvent {
  reference: string;
}
