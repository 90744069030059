import { Environment } from './model';

export const environment: Environment = {
  name: 'medical',
  production: false,
  isMedical: true,
  firebase: {
    apiKey: 'AIzaSyDuuDJygJA2KMUldKhZ__64glZA4tD5dEA',
    authDomain: 'remedee-medical.firebaseapp.com',
    projectId: 'remedee-medical',
    storageBucket: 'remedee-medical.appspot.com',
    messagingSenderId: '370121825944',
    appId: '1:370121825944:web:90c8730a0172be306dfaed',
    measurementId: 'G-FEPN3T9M4F',
  },
  functionsRegion: 'europe-west1',
  functionsBaseUrl: 'https://europe-west1-remedee-medical.cloudfunctions.net',
  reportBaseUrl: 'https://report.remedee.com',
};
