import { getApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '@env';

export function provideCustomAuth() {
  return provideAuth(() => {
    const auth = getAuth(getApp());
    if (environment.useEmulators) {
      connectAuthEmulator(auth, 'http://localhost:9099', {
        disableWarnings: false,
      });
    }
    return auth;
  });
}
