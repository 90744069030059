import { inject, Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics = inject(Analytics);

  /**
   * Log an event in Google Analytics
   */
  logEvent(eventName: string, eventParams?: EventParams): void {
    try {
      const optimizeParams = this.optimizeParams(eventParams);
      logEvent(this.analytics, eventName, optimizeParams);
    } catch (error) {
      // Don't break everything because of Analytics
      console.error(error);
    }
  }

  private optimizeParams(eventParams?: EventParams) {
    if (!eventParams) {
      return undefined;
    }

    eventParams = Object.fromEntries(
      Object.entries(eventParams).filter(([_key, value]) => value !== undefined)
    );

    // Firebase analytics support up to 25 parameters, 100 chars each
    // so we prefer to optimize: stringify everything and split in chunks { value: xxx, value1: yyy, value2: zzz }
    const chunks = JSON.stringify(eventParams).match(/.{1,100}/g) ?? [];
    return Object.fromEntries(
      chunks.map((chunk, i) => ['value' + (i > 0 ? i : ''), chunk])
    );
  }
}

export type EventParams = Record<
  string,
  string | number | boolean | null | undefined
>;
