import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { getAuth, signInWithCustomToken } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { environment } from '@env';
import debug from '@utils/debug';

const log = debug('services:auth-from-token');

interface TokenResponse {
  uid: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthFromTokenService {
  private http = inject(HttpClient);
  private baseUrl = environment.functionsBaseUrl;

  private promise?: ReturnType<typeof this.doLogin>;

  async login() {
    // Make sure we apply the process only once per session
    if (!this.promise) {
      this.promise = this.doLogin();
    }
    return this.promise;
  }

  private async doLogin() {
    const params = this.getParamsFromUrl();
    const idToken = params['token'];
    if (!idToken) {
      return;
    }

    log('login');

    try {
      const customTokenResponse = await this.getCustomToken(idToken)
        .pipe(take(1))
        .toPromise();
      const token = customTokenResponse.token;

      log('signInWithCustomToken', token);

      const auth = getAuth();
      const user = await signInWithCustomToken(auth, token);
      log('signed in as', user);
      return user;
    } catch (err) {
      log(err);
    }
    return undefined;
  }

  private getParamsFromUrl() {
    const hash = window.location.hash.substring(1);
    return Object.fromEntries(hash.split('&').map((part) => part.split('=')));
  }

  getCustomToken(idToken: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${idToken}`);

    return this.http.post<TokenResponse>(
      this.baseUrl + '/CFUser/user/custom_token',
      {},
      { headers }
    );
  }
}
