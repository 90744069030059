import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@core/services';

@Component({
  standalone: true,
  template: ``,
})
export class NotFoundPageComponent implements OnInit {
  constructor(private snackbar: SnackbarService) {}

  ngOnInit() {
    this.snackbar.warning('Page non trouvée');
  }
}
