import { getApp } from '@angular/fire/app';
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
  initializeFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { environment } from '@env';

export function provideCustomFirestore() {
  return provideFirestore(() => {
    let firestore: Firestore;
    if (environment.useEmulators) {
      firestore = initializeFirestore(getApp(), {
        experimentalForceLongPolling: true,
      });
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    } else {
      firestore = getFirestore();
    }
    return firestore;
  });
}
