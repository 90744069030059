import {
  HTTP_INTERCEPTORS,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider, inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import debug from '@utils/debug';

const log = debug('services:CloudFunctionHttpInterceptor');

export const IS_AUTHENTICATED_CLOUD_FUNCTION = new HttpContextToken(
  () => false
);

@Injectable({ providedIn: 'root' })
export class CloudFunctionHttpInterceptor implements HttpInterceptor {
  private fireAuth = inject(Auth);

  /**
   * Adds an Authorization bearer used to authenticate requests to our Cloud Functions.
   */
  intercept<T = any>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (!req.context.get(IS_AUTHENTICATED_CLOUD_FUNCTION)) {
      return next.handle(req);
    }

    return idToken(this.fireAuth).pipe(
      take(1),
      switchMap((token) => {
        log('add Authorization bearer');
        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(req);
      })
    );
  }
}

export const CloudFunctionHttpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CloudFunctionHttpInterceptor,
  multi: true,
};
