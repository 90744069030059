/**
 * Base class for our errors
 */
export class CustomError extends Error {
  context: any;

  isWarning = false;

  constructor(message: string, context?: any) {
    super(message);
    this.context = context;
  }
}

/**
 * Exception that represents error in the program logic.
 * This kind of exception should lead directly to a fix in your code.
 */
export class LogicError extends CustomError {}

/**
 * Exception raised when some data is missing in our database.
 * This kind of exception should lead directly in a fix in Firestore.
 */
export class MissingDataError extends CustomError {}

/**
 * Exception raised when some data is not found, and the cause is due to a value
 * extracted from the URL / router.
 *
 * Its message will be shown to the user so prefer French, easy to understand.
 */
export class NotFoundError extends CustomError {
  isWarning = true;
}
