import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event,
  RouterOutlet,
} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [NgIf, RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent {
  // Sets initial value to true to show loading spinner on first load
  loading = true;

  constructor(private router: Router) {
    this.router.events.subscribe((e) => {
      this.navigationInterceptor(e);
    });
  }

  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
