import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@env';
import { initSentry } from '@vendors/sentry';

initSentry();

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeFr, 'fr');

platformBrowserDynamic();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
